import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  const titleFont = "normal normal 500 16px/24px ";
  return {
    calendarView: {
      maxWidth: "1320px",
      width: "calc(100vw - 160px)",
      minHeight: "calc(100vh - 400px)",
      margin: "0 auto",
      padding: "0 20px",
      display: "flex",
      flexDirection: "column",
    },
    header: {
      display: "flex",
      alignItems: "center",
      padding: "40px 0 20px",
      color: "#fff",
      [theme.breakpoints.down(1100)]: {
        display: "block",
      },
    },
    headerTitleContainer: {
      display: "flex",
      gap: "6px",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
    },
    headerTitle: {
      display: "flex",
      fontSize: "48px",
      lineHeight: "48px",
      width: "fit-content",
      fontWeight: 500,
      color: "#000B60",
      alignItems: "end",
    },
    textTimeZone: {
      padding: "6px 12px 8px",
      background: "#F2F6FE",
      borderRadius: "8px",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: 600,
      color: "#000B60",
    },
    search: {
      display: "flex",
      alignItems: "center",
      padding: "6px 12px",
      background: "#292929",
      borderRadius: 4,
      border: "1px solid #44454B",
      width: "100%",
      maxWidth: 320,
      height: 36,

      "& input": {
        border: "none",
        fontFamily: "",
        outline: "none",
        background: "transparent",
        paddingRight: 60,
        color: "white",
        width: "100%",
        "&::placeholder": {
          color: "#ffffffc0",
        },
      },
      "& img:hover": {
        cursor: "pointer",
      },
    },
    iconClose: {
      marginRight: 8,
    },
    wrapper: {},
    row: {
      display: "flex",
      alignItems: "center",
    },
    spacer: {
      flex: 1,
    },
    month: {
      padding: "6px 20px",
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: 600,
      color: "#000B60",
    },
    tableLoading: {
      height: 150,
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      background: "transparent",
    },
    btnPool: {
      width: 154,
      height: 44,
      font: "normal normal 500 14px/16px ",
      background: "#0058FF",
      borderRadius: 60,
      border: "none",
      color: "white",
      outline: "none",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      cursor: "pointer",
      "& img": {
        width: 14,
        height: 14,
        marginRight: 4,
      },
      "& span": {
        paddingBottom: "4px",
      },
      "&:hover": {
        opacity: 1,
      },
      "&.btn-list-view": {
        fontWeight: 600,
        [theme.breakpoints.down(1100)]: {
          width: 154,
          margin: 0,
          padding: "0 16px",
        },
      },
    },
    searchGroup: {
      position: "relative",
      maxWidth: 320,
      height: 44,
      width: "70%",
      marginLeft: 6,
      border: "none",
      "& input": {
        background: "#F2F6FE",
        border: "none",
        boxSizing: "border-box",
        borderRadius: "60px",
        outline: "none",
        width: "100%",
        height: 44,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
        padding: "0 40px 0 14px",
      },
      "& img": {
        position: "absolute",
        right: "14px",
        transform: "translateY(-50%)",
        top: "50%",
      },
      [theme.breakpoints.down(1100)]: {
        width: "100%",
        maxWidth: "100%",
        marginTop: 10,
        marginLeft: 0,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginTop: 10,
        maxWidth: "100%",
      },
    },
    btn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      font: "normal normal 500 14px/18px ",
      border: 0,
      borderRadius: 2,
      outline: "none",
      height: 36,
      padding: "0 20px",
      color: "white",
      cursor: "pointer",
      transition: "0.3s",

      "&.btn-square": {
        width: 36,
        height: 36,
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#2E2E2E",
      },

      "&.btn-today": {
        padding: "16px 40px 18px",
        background: "#000B60",
        borderRadius: "60px",
        marginLeft: 32,
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: 600,
        "&:hover": {
          background: "#000B60",
        },
      },
      "&.btn-sync": {
        background: "#6788FF",
        marginLeft: "auto",
        transition: "0.3s",
        "&:hover": {
          opacity: "0.85",
        },
        "& img": {
          width: 12,
          height: 12,
          marginRight: 6,
        },
      },

      "&.btn-list-view": {
        background: "transparent",
        border: "1px solid",
        borderImage: "linear-gradient(45deg, #6154DA, #E69598) 1",
        borderRadius: 2,
        margin: "0 6px 0 auto",
        color: "white",
        fontWeight: 600,
        "& img": {
          marginRight: 6,
        },
      },
    },
    borderCalendar: {
      marginTop: 12,
      padding: "1px",
      borderRadius: "12px",
      background: "linear-gradient(90deg, #A5CCFF 0%, #E7F2FF 100%)",
    },
    calendarContainer: {
      background: "linear-gradient(235.66deg, #ECF5FF 29.71%, #FFFFFF 118.81%)",
      borderRadius: 12,
      padding: 20,
      overflow: "hidden",
    },
    calendarHeader: {
      display: "grid",
      gridTemplateColumns: "repeat(7, 1fr)",
      gridGap: 6,
      marginTop: 12,
      marginBottom: 8,
    },
    calendarHeaderItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "18px",
      lineHeight: "21px",
      fontWeight: 600,
      color: "#000B60",
    },
    calendarContent: {
      display: "grid",
      gridTemplateColumns: "repeat(7, minmax(0, 1fr))",
      gridTemplateRows: "repeat(6, auto)",
      gridGap: 6,
    },
    calendarPerDay: {
      background: "#D5E3FF",
      borderRadius: 12,
      padding: 8,
      color: "white",
      minHeight: 120,
      transition: "transform .2s",
      display: "flex",
      flexDirection: "column",
    },
    bgToday: {
      background: "#73a3da",
    },
    event: {
      borderRadius: 8,
      padding: 12,
      display: "flex",
      cursor: "pointer",
      marginBottom: 4,
      minHeight: 48,
    },
    eventInfo: {
      maxWidth: "calc(100% - 30px)",
      marginLeft: 6,
      "& div": {
        fontSize: "14px",
        lineHeight: "16px",
        color: "#000B60",
        "&:first-child": {
          fontWeight: 400,
        },
      },
    },
    poolName: {
      width: "100%",
      fontWeight: 600,
      display: "inline-block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    dayTopLeft: {
      display: "flex",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#000B60",
      fontWeight: 600,
    },
    filterBar: {
      display: "flex",
    },
    toggle: {
      display: "flex",
      alignItems: "center",
      color: "#fff",
      "& span": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
      },
      "& .MuiSwitch-thumb": {
        background: "#FFFFFF",
      },
    },
    toggleEvents: {
      margin: "0 12px 0 0",
    },
    selectBox: {
      fontFamily: "",
      fontSize: 14,
      lineHeight: "24px",
      color: "#FFFFFF",
      height: 36,
      width: 160,
      background: "#292929",
      border: "1px solid #44454B",
      borderRadius: 4,
      marginLeft: "auto",

      "&::before, &::after": {
        display: "none",
      },

      "& select": {
        padding: 0,
        paddingLeft: 12,
        height: "100%",
      },

      "& .MuiSelect-select option": {
        backgroundColor: "#292929",
      },

      "& .MuiSelect-icon": {
        color: "#FFFFFF",
        fontSize: 20,
        top: "calc(50% - 10px)",
        right: 4,
      },
      [theme.breakpoints.only("xs")]: {
        width: "100% !important" as any,
      },
    },
    timeSearch: {
      marginBottom: 8,
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: 600,
      color: "#000B60",
    },
    logo: {
      background: "black",
      borderRadius: 4,
      width: 24,
      height: 24,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "& img": {
        width: 24,
        height: 24,
      },
    },
    eventColors: {
      display: "flex",
      color: "#fff",
      marginTop: 20,
      paddingBottom: 60,
    },
    colorDetail: {
      display: "flex",
      alignItems: "center",
      marginRight: 40,
      "& span": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 400,
        color: "#000B60",
      },
    },
    dotColor: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      marginRight: 6,
      "&.bg-open": {
        background: "#5EFF8B",
      },
      "&.bg-close": {
        background: "#D01F36",
      },
      "&.bg-ido": {
        background: "#6788FF",
      },
      "&.bg-claim": {
        background: "#FFD058",
      },
    },
    result: {
      marginTop: 20,
    },
    resultHeader: {
      color: "white",
      fontFamily: "",
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 700,
      marginBottom: 8,
    },
    resultContent: {
      display: "flex",
      alignItems: "stretch",
      flexWrap: "wrap",

      "& > div": {
        marginTop: 0,
        marginRight: 6,
        marginBottom: 6,
        maxWidth: 300,
      },
    },
    noResult: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 60,
      textAlign: "center",
      color: "white",

      "& h2": {
        margin: "10px 0 2px 0",
        fontSize: "24px",
        lineHeight: "24px",
        fontWeight: 600,
        color: "#000B60",
      },

      "& p": {
        margin: 0,
        maxWidth: 220,
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 400,
        color: "#000B60",
      },
    },
    paginationBar: {
      display: "flex",
    },
    paginationBtn: {
      "& input": {
        cursor: "pointer",
        width: 36,
        height: 36,
        borderRadius: 6,
        color: "#fff",
        border: 0,
        "&:hover": {
          opacity: "0.8",
        },
        "&:focus": {
          outline: "none",
        },
        "&:disabled": {
          cursor: "not-allowed",
          opacity: 0.5,
        },
      },
    },
    btnPrevious: {
      background: "url('/images/icons/prev.svg') no-repeat center",
    },
    btnNext: {
      background: "url('/images/icons/next.svg') no-repeat center",
    },
    showMore: {
      marginTop: "auto",
      fontSize: 16,
      lineHeight: "19px",
      fontWeight: 600,
      color: "#0058FF",
      textAlign: "center",
      cursor: "pointer",
    },
  };
});

export default useStyles;
