import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    borderCardSale: {
      padding: "1px",
      borderRadius: 12,
      marginBottom: 12,
      background: "linear-gradient(90deg, #A5CCFF 0%, #E7F2FF 100%)",
    },
    cardCompletedSales: {
      overflow: "hidden",
      height: "100%",
      background: "linear-gradient(235.66deg, #ECF5FF 29.71%, #FFFFFF 118.81%)",
      borderRadius: 11,
      padding: "16px 20px",
      display: "flex",
      transition: ".2s all ease-in",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        gridTemplateColumns: "100%",
        padding: "24px 20px",
      },
    },

    leftCard: {
      gap: 8,
      width: "25%",
      display: "grid",
      gridTemplateColumns: "52px calc(100% - 52px - 8px)",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    introCard: {
      display: "flex",
      flexDirection: "column",
    },

    midCard: {
      width: "75%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    rightCard: {},

    tooltip: {
      font: "normal normal 500 14px/24px ",
      color: "#FFFFFF",
      padding: "5px 10px",
    },

    cardActiveHeader: {
      display: "Grid",
      gap: 20,
      gridTemplateColumns: "150px auto",
      marginBottom: 20,

      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "100px auto",
      },
    },

    cardActiveHeaderLeft: {
      display: "flex",
      flexDirection: "column",
    },

    icon: {
      width: 52,
      height: 52,
      overflow: "hidden",
      borderRadius: 4,
      marginRight: 8,

      [theme.breakpoints.down("md")]: {
        width: 52,
        height: 52,
      },
    },

    title: {
      textAlign: "left!important" as any,
      fontSize: "18px",
      fontWeight: 600,
      color: "#000B60",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    name: {
      fontFamily: "",
      fontSize: 16,
      lineHeight: "20px",
      color: "#AEAEAE",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginBottom: 15,

      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },

    cardActiveHeaderRight: {},

    listStatus: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      alignItems: "center",
      "& span": {
        font: "normal normal 500 12px/16px ",
      },
    },

    listInfo: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        marginTop: 10,
      },
    },

    itemInfo: {
      display: "flex",
      flexDirection: "column",
      width: "16.5%",
      marginBottom: 8,
      "& span": {
        color: "#000B60",
        "&:first-child": {
          marginBottom: 0,
        },
        "&:last-child": {
          fontWeight: 600,
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    },
    alignLeftMobile: {
      [theme.breakpoints.down("sm")]: {
        alignItems: "self-end",
      },
    },

    nameInfo: {
      font: "12px/16px ",
      color: "#AEAEAE",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginBottom: 8,
    },

    valueInfo: {
      font: "14px/24px ",
      color: "#FFFFFF",
      "& img": {
        marginBottom: -4,
        marginRight: 5,
      },
    },

    poolStatus: {
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 600,
      color: "#FFD058",
      textAlign: "center",
      textTransform: "uppercase",

      "&.tba": {
        color: "#9E63FF",
      },
      "&.up-comming": {
        color: "#6398FF",
      },
      "&.joining": {
        color: "#12A064",
      },
      "&.in-progress": {
        color: "#FFDE30",
      },
      "&.filled": {
        color: "#ff1493",
      },
      "&.ended": {
        color: "#D01F36",
      },
      "&.closed": {
        color: "#D01F36",
      },
      "&.claimable": {
        color: "#FFD058",
      },
      "&.none": {
        color: "#FF9330",
      },
      "&.time": {
        color: "#FF9330",
      },
    },

    poolStatusWarning: {
      display: "none",
      color: "#FFFFFF",
      background: "#3B3B3B",
      marginRight: 6,
      textAlign: "center",
      padding: "3px 10px",
      borderRadius: 4,
      height: 22,
      textTransform: "uppercase",
    },

    progressArea: {},

    titleProgressArea: {
      fontFamily: "",
      fontSize: 14,
      lineHeight: "20px",
      color: "#AEAEAE",

      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },

    progress: {
      display: "block",
      width: "100%",
      height: 4,
      background: "#44454B",
      borderRadius: 20,
      marginTop: 7,
      marginBottom: 7,
    },

    currentProgress: {
      height: 4,
      background: "#D01F36",
      boxShadow: "0px 4px 8px rgba(208, 31, 54, 0.4)",
      borderRadius: 20,
      display: "block",
      transition: "2s",
      position: "relative",

      "&.inactive": {
        width: "0 !important",
      },
    },

    progressInfo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontFamily: "",
      fontWeight: "bold",
      fontSize: 14,
      lineHeight: "20px",
      color: "#FFFFFF",

      "& span": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",

        "&:last-child": {
          fontFamily: "",
          fontWeight: "normal",
          color: "#AEAEAE",
        },
      },
    },

    iconCoin: {
      width: 20,
      height: 20,
      borderRadius: "50%",
    },

    headProgressArea: {
      display: "flex",
      justifyContent: "space-between",
    },

    claimTime: {
      border: "2px solid #D01F36",
      borderRadius: 60,
      fontFamily: "",
      fontWeight: "bold",
      fontSize: 18,
      lineHeight: "24px",
      textAlign: "center",
      color: "#D01F36",
      height: 32,
      padding: "4px 13px",
      display: "flex",
      alignItems: "center",
      alignContent: "center",
      flexDirection: "row",
      position: "relative",
      marginTop: -10,

      "& img": {
        marginRight: 10,
        position: "relative",
        top: -1,
      },
    },

    iconCurrentProgress: {
      width: 23,
      height: 23,
      borderRadius: 23,
      background: "#B073FF",
      top: -10,
      right: -5,
      position: "absolute",
    },

    [theme.breakpoints.down("md")]: {
      valueInfo: {
        fontSize: 14,
        lineHeight: "24px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },

    [theme.breakpoints.only("xs")]: {
      leftCard: {
        gridTemplateColumns: "48px calc(100% - 48px - 8px)",
      },
      listInfo: {
        "& li:last-child": {},
      },
      nameInfo: {
        fontSize: 12,
        lineHeight: "16px",
      },
      valueInfo: {
        fontSize: 14,
        lineHeight: "24px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      tokenImg: {
        width: 48,
        height: 48,
      },
      icon: {
        width: 48,
        height: 48,
      },
    },
  };
});

export default useStyles;
