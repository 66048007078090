// @ts-nocheck
import {
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withWidth,
} from "@material-ui/core";
import { numberWithCommas } from "../../../../utils/formatNumber";
import useStyles from "./style";

function createData(
  name: string,
  base: number,
  bronze: number,
  silver: number,
  gold: number,
  platinum: number,
  diamond: number
) {
  return { name, base, bronze, silver, gold, platinum, diamond };
}

const rows = [
  createData("100% Refund", 1, 1, 1, 1, 1, 1),
  createData("Guaranteed Allocation at IDO", 0, 1, 1, 1, 1, 1),
  createData("FCFS Round", 1, 1, 1, 1, 1, 1),
  createData("Social Interation Required", 1, 1, 0, 0, 0, 0),
  createData("Crowdfunding and Private Pools", 0, 0, 1, 1, 1, 1),
  createData("Incubation Airdrops", 0, 0, 0, 0, 0, 1),
  createData("Fremium Access to RWA Inc. Marketplace", 0, 0, 1, 1, 1, 1),
  createData("Discounted Merchandise", 0, 0, 0, 0, 1, 1),
];

const rowsMobile = [
  {
    name: "Bronze",
    values: [
      "Minimum amount of point required : <span>2000</span>",
      "Social interaction requirements",
    ],
  },
  {
    name: "Silver",
    values: [
      "Minimum amount of point required : <span>20,000</span>",
      "Social interaction requirements",
    ],
  },
  {
    name: "Gold",
    values: [
      "Minimum amount of point required : <span>50,000</span>",
      "Guaranteed allocation",
      "Exclusive pools",
    ],
  },
  {
    name: "Diamond",
    values: [
      "Minimum amount of RWA point required : <span>200,000</span>",
      "Guaranteed allocation",
      "Exclusive pools",
      "Occasional airdrop of NFT and tokens",
      "Private prosperity group",
    ],
  },
];

const TierBenefits = (props: any) => {
  const styles = useStyles();
  return (
    <div className={styles.tabTierBenefits}>
      <Hidden smDown>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table className={styles.table} aria-label="simple table">
            <TableHead className={styles.tableHeaderWrapper}>
              <TableRow>
                <TableCell>Tier Benefits</TableCell>
                <TableCell className={styles.tierHeader} align="right">
                  <p>Base</p>
                </TableCell>
                <TableCell className={styles.tierHeader} align="right">
                  <p>Bronze</p>
                </TableCell>
                <TableCell className={styles.tierHeader} align="right">
                  <p>Silver</p>
                </TableCell>
                <TableCell className={styles.tierHeader} align="right">
                  <p>Gold</p>
                </TableCell>
                <TableCell className={styles.tierHeader} align="right">
                  <p>Platinum</p>
                </TableCell>
                <TableCell className={styles.tierHeader} align="right">
                  <p>Diamond</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Minimum Staking Points
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ borderRadius: 0 }}
                  align="right"
                >
                  {numberWithCommas("0")}
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  {numberWithCommas("2000")}
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  {numberWithCommas("20000")}
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  {numberWithCommas("50000")}
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  {numberWithCommas("100000")}
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  {numberWithCommas("200000")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Pool Weight / Allocation Multiplier
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  1x
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  2x
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  4x
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  10x
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  20x
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  40x
                </TableCell>
              </TableRow>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.base === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.bronze === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.silver === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.gold === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.platinum === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.diamond === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>

      <Hidden mdUp>
        <div className={styles.tierBenefitsMobile}>
          <div className={styles.tableContainer}>
            <h2>Minimum Staking Points</h2>
            <div className="tier-mobile">
              <div>
                <p>Base</p>
                <p>{numberWithCommas("0")}</p>
              </div>
              <div>
                <p>Bronze</p>
                <p>{numberWithCommas("2000")}</p>
              </div>
              <div>
                <p>Silver</p>
                <p>{numberWithCommas("20000")}</p>
              </div>
              <div>
                <p>Gold</p>
                <p>{numberWithCommas("50000")}</p>
              </div>
              <div>
                <p>Platinum</p>
                <p>{numberWithCommas("100000")}</p>
              </div>
              <div>
                <p>Diamond</p>
                <p>{numberWithCommas("200000")}</p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 12 }} className={styles.tableContainer}>
            <h2>Pool Weight / Allocation Multiplier</h2>
            <div className="tier-mobile">
              <div>
                <p>Base</p>
                <p>1x</p>
              </div>
              <div>
                <p>Bronze</p>
                <p>2x</p>
              </div>
              <div>
                <p>Silver</p>
                <p>4x</p>
              </div>
              <div>
                <p>Gold</p>
                <p>10x</p>
              </div>
              <div>
                <p>Platinum</p>
                <p>20x</p>
              </div>
              <div>
                <p>Diamond</p>
                <p>40x</p>
              </div>
            </div>
          </div>
          {rows.map((row) => (
            <div style={{ marginTop: 12 }} className={styles.tableContainer}>
              <h2>{row.name}</h2>
              <div className="tier-mobile">
                <div>
                  <p>Base</p>
                  <p>
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.base === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </p>
                </div>
                <div>
                  <p>Bronze</p>
                  <p>
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.bronze === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </p>
                </div>
                <div>
                  <p>Silver</p>
                  <p>
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.silver === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </p>
                </div>
                <div>
                  <p>Gold</p>
                  <p>
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.gold === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </p>
                </div>
                <div>
                  <p>Platinum</p>
                  <p>
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.platinum === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </p>
                </div>
                <div>
                  <p>Diamond</p>
                  <p>
                    <img
                      src={`/images/account_v3/icons/icon_table_${
                        row.diamond === 0 ? "false" : "true"
                      }.svg`}
                      alt=""
                    />
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Hidden>
    </div>
  );
};

export default withWidth()(TierBenefits);
