import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { Link } from "react-router-dom";
import { TIERS } from "../../../constants";
import { trimMiddlePartAddress } from "../../../utils/accountAddress";
import useStyles from "./style";

const titleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      paddingTop: 0,
      textAlign: "center",
    },
    closeButton: {
      position: "absolute",
      right: 12,
      top: 12,
      color: "#0058FF",
      backgroundColor: "#F2F6FE",
      padding: 4,
      "&:hover": {
        backgroundColor: "#D4D4D4",
      },
    },
    svgIcon: {
      fontSize: 5,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof titleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  customClass: string;
  networkAvailable?: string;
}

export interface ComponentProps {
  opened: boolean;
  handleClose: () => void;
}

const DialogTitle = withStyles(titleStyles)((props: DialogTitleProps) => {
  const { children, classes, customClass, onClose, ...other } = props;

  const customStyles = {
    color: "white",
    paddingBottom: 0,
  };

  return (
    <MuiDialogTitle
      disableTypography
      className={`${classes.root} ${customClass}`}
      {...other}
    >
      <Typography variant="h5" style={customStyles}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    color: "#FFFFFF",
    margin: "0 !important",
    display: "flex",
    justifyContent: "center",
  },
}))(MuiDialogContent);

const WhitelistNotificationModal: React.FC<any> = (props: any) => {
  const {
    poolDetails,
    connectedAccount,
    handleClose,
    showKyc,
    showMinTier,
    showSocialTask,
  } = props;
  const styles = useStyles();

  return (
    <Dialog
      open
      fullWidth={true}
      maxWidth={"xs"}
      className={styles.socialDialog}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        customClass={styles.dialogTitle}
      >
        <div className={styles.titleDetail}>
          <img src="/images/tokens.svg" alt="" />
          <span>
            {showMinTier ? "Tier Required" : showKyc && "KYC required"}
          </span>
        </div>
      </DialogTitle>
      <DialogContent>
        {showMinTier ? (
          <div className={styles.errroTier}>
            <span>
              You haven't achieved min tier (
              {TIERS[poolDetails?.minTier]?.name || ""}) to register for
              Interest yet. To upgrade your Tier, please click&nbsp;
              <Link
                to="/staking-pools"
                style={{
                  color: "#0058FF",
                  fontWeight: 600,
                  textDecoration: "underline",
                }}
              >
                here{" "}
              </Link>
            </span>{" "}
            {/* . */}
          </div>
        ) : showKyc ? (
          <div className={styles.alertVerifyEmail}>
            <span>
              The connected wallet address (
              {trimMiddlePartAddress(connectedAccount || "")}) is
              unverified.&nbsp;
              <a
                href="https://verify-with.blockpass.org/?clientId=rwa_launchpad_kycaml_a4a02"
                target="_blank"
                rel="noreferrer"
              >
                Please submit KYC now
              </a>
              &nbsp;or switch to a verified address.
            </span>
          </div>
        ) : (
          showSocialTask && (
            <div className={styles.alertVerifyEmail}>
              <p>
                <p>Please sign the message to register.</p>
                <br />
                To increase your chances on winning guaranteed allocation please
                complete social tasks on
                <a
                  href={poolDetails?.socialRequirement?.gleam_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  &nbsp;the following link
                </a>
              </p>
            </div>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};

export default WhitelistNotificationModal;
