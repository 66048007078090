import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@material-ui/core";
import BigNumber from "bignumber.js";
import { useEffect, useMemo, useRef, useState } from "react";
import useCommonStyle from "../../../styles/CommonStyle";
import usePoolStyles from "../Pool/style";
import useStyles from "./style";
import { formatDecimal } from "../../../utils";
import ProgressMovement from "../../../components/Base/Progress";
import {
  BUY_RWA_LINK,
  StyledMenu,
} from "../../../components/Base/HeaderDefaultLayout/HeaderDefaultLayout";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { isNumber } from "lodash";

const closeIcon = "/images/icons/close.svg";
const DEFAULT_TOKEN_DECIMALS = 18;

const ModalStake = (props: any) => {
  const styles = useStyles();
  const poolStyles = usePoolStyles();
  const commonStyles = useCommonStyle();
  const progressRef = useRef<any>();

  const {
    open,
    onConfirm,
    onClose,
    amount,
    setAmount,
    tokenDetails,
    stakingAmount,
    min,
    max,
    tokenBalance,
    wrongChain,
    tokenAllowance,
    handleApprove,
  } = props;

  const [progress, setProgress] = useState("0");
  const [anchorBuyMenu, setAnchorBuyMenu] = useState<null | HTMLElement>(null);
  const isOpenBuyMenu = Boolean(anchorBuyMenu);

  const openBuyMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorBuyMenu(event.currentTarget);
  };

  const handleCloseBuyMenu = (index: number) => {
    setAnchorBuyMenu(null);
    // @ts-ignore
    isNumber(index) && window.open(BUY_RWA_LINK[index].link, "_blank").focus();
  };

  useEffect(() => {
    setProgress(
      (
        ((!!Number(amount || 0) ? Number(amount || 0) : 0) /
          (!!Number(tokenBalance || 0) ? Number(tokenBalance || 0) : 0)) *
        100
      ).toFixed(0) || "0"
    );
  }, [amount, tokenBalance, setProgress]);

  const needApprove = useMemo(() => {
    return new BigNumber(tokenAllowance).lt(amount);
  }, [tokenAllowance, amount]);

  const tokenDecimal = useMemo(
    () => tokenDetails?.decimals || DEFAULT_TOKEN_DECIMALS,
    [tokenDetails?.decimals]
  );

  const handleChangeStakeAmount = (event: any) => {
    let newAmount = formatDecimal(
      new BigNumber(event.target.value),
      tokenDecimal
    );
    let maxAmount =
      !Number(max) || new BigNumber(tokenBalance).lt(max) ? tokenBalance : max;
    setAmount(new BigNumber(newAmount).gt(maxAmount) ? maxAmount : newAmount);
  };

  const onChangeStakeAmount = (percent: number) => {
    setAmount(+tokenBalance * percent + "");
  };

  const alreadyStake = stakingAmount && Number(stakingAmount) > 0;

  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={commonStyles.modal + " " + styles.modalStake}
    >
      <div className={poolStyles.modalContent}>
        <DialogTitle
          id="alert-dialog-slide-title"
          className={poolStyles.modalHeader}
        >
          <img src={closeIcon} alt="" onClick={onClose} className="btn-close" />
          <div className="title">Stake</div>
        </DialogTitle>
        <DialogContent className={poolStyles.modalBody}>
          <div className="token-type">
            <div className="token-type-title">Token</div>
            <div className="token-detail">
              <div>{tokenDetails?.symbol}</div>
            </div>
          </div>
          {alreadyStake && (
            <div className="token-type">
              <div className="token-type-title">Staking</div>
              <div className="token-detail">{stakingAmount}</div>
            </div>
          )}
          {min && Number(min) > 0 && (
            <div className="token-type">
              <div className="token-type-title">Min Stake</div>
              <div className="token-detail token-min-stake">{min}</div>
            </div>
          )}
          <div className="subtitle">
            {alreadyStake ? "Additional stake amount" : "Stake Amount"}
            <div className="token-balance">
              (Balance: {new BigNumber(tokenBalance).toFixed(2)}{" "}
              {tokenDetails?.symbol})
            </div>
          </div>
          <div className="input-group">
            <input
              value={amount}
              onChange={handleChangeStakeAmount}
              type="number"
              min="0"
            />
            <span>{tokenDetails?.symbol}</span>
          </div>

          <div className={poolStyles.groupButtonPercent}>
            <button
              onClick={() => onChangeStakeAmount(0.25)}
              className={poolStyles.btnSelectPercent}
            >
              25%
            </button>
            <button
              onClick={() => onChangeStakeAmount(0.5)}
              className={poolStyles.btnSelectPercent}
            >
              50%
            </button>
            <button
              onClick={() => onChangeStakeAmount(0.75)}
              className={poolStyles.btnSelectPercent}
            >
              75%
            </button>
            <button
              onClick={() => onChangeStakeAmount(1)}
              className={poolStyles.btnSelectPercent}
            >
              100%
            </button>
          </div>
        </DialogContent>

        <div
          className={`${poolStyles.progressArea} ${poolStyles.modalProgress}`}
        >
          <ProgressMovement
            amount={amount}
            maxAmount={tokenBalance}
            setAmount={setAmount}
          />
        </div>

        <DialogActions className={poolStyles.modalFooter}>
          {needApprove ? (
            <button
              className={`${poolStyles.btn} ${poolStyles.btnModal} ${poolStyles.btnEnable}`}
              onClick={() => handleApprove()}
              disabled={wrongChain}
            >
              Approve
            </button>
          ) : (
            <button
              className={`${poolStyles.btn} ${poolStyles.btnModal} ${poolStyles.btnStakeModal}`}
              onClick={onConfirm}
              disabled={isNaN(amount) || Number(amount) <= 0}
            >
              Stake
            </button>
          )}
          {/* <button
            className={`${poolStyles.btn} ${poolStyles.btnModal} ${poolStyles.btnGetPkfModal}`}
            onClick={openBuyMenu}
          >
            Get RWA
            <ArrowDropDown style={{ fill: "#0058FF" }} />
          </button> */}
          <StyledMenu
            id="buy-menu"
            MenuListProps={{
              "aria-labelledby": "buy-menu-button",
            }}
            anchorEl={anchorBuyMenu}
            open={isOpenBuyMenu}
            className={styles.learnMenu}
            onClose={handleCloseBuyMenu}
          >
            {BUY_RWA_LINK.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  href={item.link}
                  target={"_blank"}
                  style={{
                    fontFamily: "",
                  }}
                  className="menu-item"
                  onClick={() => handleCloseBuyMenu(index)}
                  disableRipple
                >
                  <a
                    key={index}
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div
                      style={{
                        display: "flex ",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <img
                        src={item.img}
                        width={20}
                        height={20}
                        alt={item.name}
                      />
                      <span style={{ color: "#000B60" }}>{item.name}</span>
                    </div>
                  </a>
                </MenuItem>
              );
            })}
          </StyledMenu>
        </DialogActions>
        {/* {transactionHashes[0].isApprove && <p className={styles.notice}>Please be patient and no need to approve again, you can check the transaction status on Etherscan.</p>} */}
      </div>
    </Dialog>
  );
};

export default ModalStake;
