import withWidth from "@material-ui/core/withWidth";
import { isNumber } from "lodash";
import { TIERS } from "../../../../constants";
import { numberWithCommas } from "../../../../utils/formatNumber";
import useStyles from "./style";
import { useHistory } from "react-router-dom";

const arrowRight = "/images/arrowRightWhite.svg";

const TierList = (props: any) => {
  const styles = useStyles();
  const history = useHistory();

  const {
    tiersBuyLimit,
    tiers,
    userTier,
    showMoreInfomation,
    hideStatistics,
    totalRedKitePoints,
    pointsLeftToNextTier,
    viewTiers,
  } = props;

  const tiersValue =
    userTier - 1 && userTier - 1 > 0 ? TIERS[userTier - 1] : TIERS[0];

  if (totalRedKitePoints === "0") {
    return (
      <div className={styles.tierListComponent}>
        <div className={styles.noTierContent}>
          You currently have <span>0 RWA</span> staking points.
        </div>
        <button
          className={styles.buttonStakeNow}
          onClick={() => history.push("/staking-pools?benefit=ido-only")}
        >
          Stake Now
        </button>
      </div>
    );
  }

  return (
    <div className={styles.tierListComponent}>
      <div className={styles.nnn1424h}>Your current tier:</div>
      <div className={styles.currentTier}>
        {tiersValue?.name}
        <span>{totalRedKitePoints} RWAs</span>
      </div>

      <ul className={styles.tierList}>
        {tiers.length > 0 &&
          tiers?.map((tier: any, idx: any) => {
            return (
              <li
                key={idx}
                style={{
                  color: userTier > idx ? TIERS?.[idx]?.bgColor : "#727272",
                }}
                className={
                  styles?.tierInfo +
                  (userTier - 1 >= idx ? " active " : " ") +
                  (userTier - 1 > idx ? " line-active " : " ") +
                  TIERS?.[idx]?.name +
                  (hideStatistics ? " hide-statistics" : "")
                }
              >
                <div>
                  <div className={`icon ${userTier === idx && "current-tier"}`}>
                    <img
                      className="icon-inner"
                      src={TIERS?.[idx]?.icon}
                      alt=""
                    />
                  </div>
                  <div className="info">
                    <span
                      className={
                        userTier > idx
                          ? "tier-name tier-completed"
                          : "tier-name"
                      }
                    >
                      {TIERS?.[idx]?.name}
                    </span>
                    {!showMoreInfomation && (
                      <span>{idx ? numberWithCommas(tier) : 0} RWAs</span>
                    )}
                    {showMoreInfomation && !hideStatistics && (
                      <span>{numberWithCommas(tiersBuyLimit[idx])} RWAs</span>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
      </ul>

      {isNumber(+pointsLeftToNextTier) && +pointsLeftToNextTier > 0 ? (
        <div className={styles.pointsLeft}>
          Get <span>{numberWithCommas(pointsLeftToNextTier)}</span> more RWA Pad
          Points to achieve {TIERS?.[userTier + 1]?.name} Tier
          <button className={styles.buttonViewTier} onClick={() => viewTiers()}>
            View RWAs Tiers
          </button>
        </div>
      ) : (
        <div className={styles.pointsLeft}>
          You achieve Diamond tier
          <button className={styles.buttonViewTier} onClick={() => viewTiers()}>
            View RWA Tiers
          </button>
        </div>
      )}
    </div>
  );
};

export default withWidth()(TierList);
