import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { ConnectorNames } from "../../../../constants/connectors";
import {
  APP_NETWORKS_ID,
  APP_NETWORKS_SUPPORT,
  BSC_CHAIN_ID,
  chainId,
  ChainIdNameMapping,
  NETWORK_NAME_MAPPINGS,
} from "../../../../constants/network";
import usePrevious from "../../../../hooks/usePrevious";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { alertFailure } from "../../../../store/actions/alert";
import {
  NetworkUpdateType,
  settingAppNetwork,
  settingCurrentConnector,
} from "../../../../store/actions/appNetwork";
import {
  connectWalletSuccess,
  disconnectWallet,
} from "../../../../store/actions/wallet";
import getAccountBalance from "../../../../utils/getAccountBalance";
import { getAppNetworkName } from "../../../../utils/network/getAppNetworkName";
import { refreshToken } from "../../../../utils/refreshToken";
import { requestSupportNetwork } from "../../../../utils/setupNetwork";
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect-v2";
import { BscWallet } from "../../../../connectors/BinanceWallet";
import { isMobile } from "react-device-detect";

const useProviderConnect = (
  setOpenConnectDialog?: Dispatch<SetStateAction<boolean>>,
  openConnectDialog?: boolean,
  binanceAvailable?: boolean
) => {
  const dispatch = useDispatch();

  const { appChainID, walletChainID } = useTypedSelector(
    (state) => state.appNetwork
  ).data;

  const [account, setAccount] = useState<string | undefined>(undefined);

  const [appNetworkLoading, setAppNetworkLoading] = useState(false);
  const [walletNameSuccess, setWalletNameSuccess] = useState<
    string | undefined
  >(undefined);
  const [walletName, setWalletName] = useState<(undefined | string)[]>([]);
  const [currentConnector, setCurrentConnector] = useState<
    undefined | MetaMask | WalletConnect | BscWallet
  >(undefined);
  const [connectWalletLoading, setConnectWalletLoading] = useState(false);
  const [loginError, setLoginError] = useState("");

  const {
    isActive: active,
    connector,
    chainId,
    account: connectedAccount,
  } = useWeb3React();

  const deactivate = () => {
    if (!currentConnector) return;
    if (currentConnector?.deactivate) {
      void currentConnector.deactivate();
    } else {
      void currentConnector.resetState();
    }
  };

  const activate = async () => {
    if (currentConnector) {
      await currentConnector.activate(parseInt(appChainID));
    }
  };

  const previousAccount = usePrevious(account);
  const activePrevious = usePrevious(active);
  const previousConnector = usePrevious(connector);

  useEffect(() => {
    if (
      connectWalletLoading &&
      ((active && !activePrevious) ||
        (connector && connector !== previousConnector))
    ) {
      setConnectWalletLoading(false);
      setOpenConnectDialog && setOpenConnectDialog(false);
    }
  }, [
    active,
    connector,
    previousAccount,
    previousConnector,
    activePrevious,
    connectWalletLoading,
    setOpenConnectDialog,
    setConnectWalletLoading,
  ]);

  useEffect(() => {
    if (currentConnector && currentConnector.provider?.on && !active) {
      const handleAccountUpdate = (accounts: any) => {
        if (accounts[0]) {
          setAccount(accounts[0]);
        } else setAccount(undefined);
      };
      const handleChainUpdate = (chainIdHex: any) => {
        if (chainIdHex) {
          const chainId = Number(chainIdHex).toString();

          if (APP_NETWORKS_ID.indexOf(chainId.toString()) >= 0) {
            dispatch(
              settingAppNetwork(
                NetworkUpdateType.App,
                APP_NETWORKS_ID[
                  APP_NETWORKS_ID.indexOf(chainId.toString())
                ] as string
              )
            );

            switchNetwork(
              APP_NETWORKS_ID[
                APP_NETWORKS_ID.indexOf(chainId.toString())
              ] as string,
              chainId
            );
            return;
          }
          switchNetwork(appChainID, chainId);
          chainId &&
            dispatch(
              settingAppNetwork(NetworkUpdateType.Wallet, chainId.toString())
            );
        }
      };

      const handleWeb3ReactError = (err: any) => {
        if (err === "NaN ChainId") {
          dispatch(settingAppNetwork(NetworkUpdateType.Wallet, undefined));
          setLoginError(
            `App network (${appChainID}) doesn't mach to network selected in wallet: NaN. Learn how to change network in wallet or`
          );
        }
      };

      currentConnector.provider.on("chainChanged", handleChainUpdate);
      currentConnector.provider.on("accountsChanged", handleAccountUpdate);
      // @ts-ignore
      currentConnector.provider.on("error", handleWeb3ReactError);
      currentConnector.provider.on("disconnect", handleConnectorDisconnect);

      return () => {
        if (
          currentConnector &&
          currentConnector.provider?.removeListener &&
          active
        ) {
          currentConnector.provider.removeListener(
            "accountsChanged",
            handleChainUpdate
          );
          currentConnector.provider.removeListener(
            "disconnect",
            handleConnectorDisconnect
          );
        }
      };
    }

    return;
  }, [currentConnector, connectedAccount]);

  useEffect(() => {
    currentConnector && setAppNetworkLoading(true);
  }, [appChainID]);
  useEffect(() => {
    if (!appNetworkLoading) {
      setOpenConnectDialog && setOpenConnectDialog(false);
      setConnectWalletLoading(false);
    }
  }, [appNetworkLoading]);
  useEffect(() => {
    const tryLoginAfterSwitch = async () => {
      if (currentConnector && APP_NETWORKS_SUPPORT[appChainID]) {
        let ok = true;
        if (appChainID === BSC_CHAIN_ID) {
          ok =
            !!binanceAvailable ||
            document.readyState === "complete" ||
            document.readyState === "interactive";
        }
        ok &&
          (await tryActivate(
            currentConnector,
            appChainID,
            walletName[walletName.length - 1] as string
          ));
      }
    };

    currentConnector &&
      appChainID &&
      walletName.length > 0 &&
      tryLoginAfterSwitch();
  }, [currentConnector, appChainID, walletName, binanceAvailable]);

  useEffect(() => {
    walletChainID &&
      !openConnectDialog &&
      !appNetworkLoading &&
      switchNetwork(appChainID, walletChainID);
  }, [walletChainID, appNetworkLoading, appChainID, openConnectDialog]);
  useEffect(() => {
    if (!connectWalletLoading) {
      chainId &&
        dispatch(
          settingAppNetwork(NetworkUpdateType.Wallet, chainId.toString())
        );
      if (connectedAccount) {
        setAccount(connectedAccount);
        const dataSend = {
          type: "FROM_REDKITE",
          data: {
            address: connectedAccount,
            login: true,
          },
        };

        window.postMessage(dataSend, "*");
      }
    }
  }, [connectWalletLoading, connectedAccount, chainId]);
  const handleProviderChosen = (
    name: string,
    connector: MetaMask | WalletConnect | BscWallet
  ) => {
    setCurrentConnector(connector);
    walletName.indexOf(name) < 0 && setWalletName([...walletName, name]);
  };

  const switchNetwork = (appChainID: string, walletChainID: string) => {
    if (appChainID && walletChainID) {
      Number(appChainID) !== Number(walletChainID)
        ? setLoginError(
            `App network (${getAppNetworkName(
              appChainID
            )}) doesn't match to network selected in wallet: ${
              ChainIdNameMapping[Number(walletChainID) as chainId]
            }.`
          )
        : setLoginError("");
    }

    return;
  };

  const tryActivate = useCallback(
    async (
      connector: MetaMask | WalletConnect | BscWallet,
      appChainID: string,
      wallet: string
    ) => {
      try {
        if (!connectWalletLoading) {
          setConnectWalletLoading(true);
          if (wallet === ConnectorNames.MetaMask) {
            await requestSupportNetwork(appChainID, wallet);
          }
          if (connector && walletName) {
            await activate()
              .then(() => {
                dispatch(settingCurrentConnector(wallet));
                setWalletNameSuccess(wallet);
              })
              .catch(async (error) => {
                if (error) {
                  console.debug("Error when activate: ", error.message);
                  dispatch(disconnectWallet());
                  setCurrentConnector(undefined);
                  setConnectWalletLoading(false);
                  setWalletName([]);
                  localStorage.removeItem("walletconnect");
                  const currentChainId = await connector?.provider?.chainId;
                  if (isMobile && !window.ethereum) {
                    dispatch(
                      // @ts-ignore
                      alertFailure(`Please use Metamask's browser`)
                    );
                    return;
                  }
                  dispatch(
                    // @ts-ignore
                    alertFailure(`Can't connect`)
                  );

                  return;
                } else {
                  dispatch(disconnectWallet());
                  setConnectWalletLoading(false);
                  setWalletName(walletName.filter((name) => wallet !== name));
                  console.debug("Error when try to activate: ", error.message);
                  return;
                }
              });
          }
        }
      } catch (error: any) {
        setLoginError(error.message);
        setCurrentConnector(undefined);
      }

      setAppNetworkLoading(false);
    },
    [
      connectWalletLoading,
      walletName,
      activate,
      setOpenConnectDialog,
      dispatch,
      connectedAccount,
    ]
  );

  function parseChainId(chainId: string) {
    return Number.parseInt(chainId, 16);
  }

  useEffect(() => {
    const getAccountDetails = async () => {
      if (appChainID && connectedAccount && walletNameSuccess) {
        const acccessToken = localStorage.getItem(
          `access_token:${connectedAccount}`
        );
        const accountBalance = await getAccountBalance(
          appChainID,
          walletChainID,
          connectedAccount as string,
          walletNameSuccess
        );

        dispatch(
          connectWalletSuccess(walletNameSuccess, [connectedAccount], {
            [connectedAccount]: new BigNumber(accountBalance._hex)
              .div(new BigNumber(10).pow(18))
              .toFixed(5),
          })
        );
        if (!acccessToken) refreshToken();

        setConnectWalletLoading(false);
      }
    };
    getAccountDetails();
  }, [
    walletNameSuccess,
    connectedAccount,
    appChainID,
    walletChainID,
    dispatch,
  ]);

  const handleConnectorDisconnect = useCallback(() => {
    dispatch(disconnectWallet());
    dispatch(settingCurrentConnector(undefined));
    dispatch(settingAppNetwork(NetworkUpdateType.Wallet, undefined));

    localStorage.removeItem("walletconnect");

    deactivate();
    setAccount(undefined);
    setWalletName([]);
    setWalletNameSuccess(undefined);
    setCurrentConnector(undefined);
    setConnectWalletLoading(false);
    setLoginError("");
  }, []);

  return {
    handleProviderChosen,
    setWalletName,
    walletName,
    connectWalletLoading,
    walletNameSuccess,
    loginError,
    currentConnector,
    appNetworkLoading,
    handleConnectorDisconnect,
  };
};

export default useProviderConnect;
